// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { CartProvider, ShopifyProvider } from '@shopify/hydrogen-react';

export function wrapPageElement({ element }) {
  return (
    <ShopifyProvider
      storeDomain="corpocritters.myshopify.com"
      storefrontToken="a098acd41285af23987d468683826937"
      storefrontApiVersion="2024-07"
      countryIsoCode="US"
      languageIsoCode="EN"
    >
      <CartProvider>
        <AnimatePresence mode="wait">{element}</AnimatePresence>
        {/* {element} */}
      </CartProvider>
    </ShopifyProvider>
  );
}
